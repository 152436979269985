var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-in"},[(_vm.signInloading)?_c('loading',{attrs:{"active":_vm.signInloading,"can-cancel":true,"on-cancel":_vm.onCancel,"is-full-page":_vm.fullPage}}):_vm._e(),_c('a-row',{attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{span: 12, offset: 0},"xl":{span: 8, offset: 0}}},[_c('div',{staticClass:"brand"},[_c('img',{attrs:{"src":"images/logo-domos.png","alt":"domos","align":"middle"}})]),_c('h5',{staticClass:"font-regular text-muted"},[_vm._v("Use your Google account to sign-in")]),_c('div',{staticClass:"sign-in-gateways"},[_c('a-button',{on:{"click":function($event){return _vm.handleSignUp('gmail')}}},[_c('span',{staticClass:"text"},[_vm._v("Continue with Google")]),_c('img',{attrs:{"src":"images/logos/Google__G__Logo.svg.png","alt":"Continue with Google"}})])],1),_c('h5',{staticClass:"font-regular text-muted"},[_vm._v("Or enter your email and password to sign in")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Email","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] },
						]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your email!' }] },\n\t\t\t\t\t\t]"}],ref:"email",attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{ rules: [{ required: true, message: 'Please input your password!' }] },
						]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your password!' }] },\n\t\t\t\t\t\t]"}],ref:"password",attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-switch',{model:{value:(_vm.rememberMe),callback:function ($$v) {_vm.rememberMe=$$v},expression:"rememberMe"}}),_vm._v(" Remember Me ")],1),_c('a-form-item',{staticClass:"mb-10"},[(_vm.regFailed)?_c('h7',{staticStyle:{"color":"red"}},[_vm._v("Wrong Credentials. Please make sure you are using the right email. If you can't remember the password, please use the link below to restart it. ")]):_vm._e()],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN IN ")])],1),_c('a-button',{staticClass:"login-form-forgot",attrs:{"type":"link"},on:{"click":_vm.showModal}},[_vm._v(" Forgot your password? ")]),(_vm.resetMessage)?_c('p',[_vm._v(_vm._s(_vm.resetMessage))]):_vm._e()],1),_c('p',{staticClass:"font-semibold text-muted"},[_vm._v("Don't have an account? Need Support? "),_c('a',{attrs:{"href":"mailto:support@joindomos.com?subject=Sign In Support","target":"_blank"}},[_vm._v("Email Us")])])],1)],1),(_vm.visible)?_c('MainModal',{attrs:{"title":_vm.modalTitle,"handle-cancel":_vm.modalHandleCancel},on:{"handleOk":_vm.modalHandleOk}},[_c('MainForm',{ref:"formFields",attrs:{"formFields":_vm.resetInputs,"title":_vm.modalTitle}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }